<template>
  <div class="receipt-list">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="loading"
      :searchFields="searchFields"
      :colSize="colSize"
      :total="total"
      :autoBtnList="autoBtnList"
      :tableData="tableData"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="false"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :initFormData="initFormData"
      :selectTableSummary="selectTableSummary"
      :fieldOperateList="fieldOperateList"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @getTableData="getTableData"
    >
      <!-- <template slot="operate">
        <label class="scan-label">扫码确认</label>
        <el-input
          size="mini"
          suffix-icon="el-icon-full-screen"
          style="width: 100px"
        />
        <el-tooltip class="tip" effect="dark" content="扫码时输入框需要聚焦" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </template> -->

    </search-table>
    <receipt-detail ref="detail" title="回单查看"></receipt-detail>
    <way-bill-detail ref="wayBillDetail" ></way-bill-detail>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import ReceiptDetail from './components/receiptDetail.vue'
import WayBillDetail from '@/components/WayBillDetail/index.vue'
import config from '@/const/receipt/receipt-list.js'
import { latestWeek, isParamsNotNull } from '@/utils/util'
import { receiptManagement, receiptConfirm, exportReceiptList, queryExcelDownloadStatus, downloadExcel } from '@/api/receipt'

export default {
  name: 'ReceiptList',
  components: {
    SearchTable,
    ReceiptDetail,
    WayBillDetail,
  },
  data() {
    return {
      visible: false,
      loading: false,
      tempFileName: null, // 下载的临时文件名
      total: 0,
      initFormData: { // 初始化的表单数据
        deliveryDt: latestWeek
      },
      formParams: {}, // 当前查询的参数
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 6, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary // 需要选中合计的字段
    }
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    },
    
    // 基础表格配置项
    baseColumns() {
      return config.baseColumn(this)
    },
    // 单元格field跳转的处理  key是field，value必须是函数
    fieldOperateList() {
      return {
        count: this.getReceiptDetail, // 必须是函数
        wayId: this.getWayBillDetail, // 运单号详情
      }
    }
  },
  destroyed() {
    // 页面销毁后需要清除计时器
    this.clear()
  },
  created() {
    // mock
    this.tableColumns = config.baseColumn()
    this.searchFields = config.searchFields()

  },
  methods: {
    // 查看运单详情
    getWayBillDetail(row, column) {
      this.$refs.wayBillDetail.visible = true
      this.$refs.wayBillDetail.wayId = row.wayId
      // console.log(row, column)
    },
    // 确认回单
    async receiptConfirm() {
      let length = this.$refs.searchTable.selectTableData.length
      if (length) {
        const wayIds = this.$refs.searchTable.selectTableData.reduce((prev, v) => {
          prev.push(v.wayId)
          return prev
        }, [])
        try {
          await this.$confirm('确认此回单吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          //todos 删除地址
          const res = await receiptConfirm(wayIds)
          if(res.data) {
            this.$message.success('回单成功')
            this.getTableData(this.formParams)
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$message.error('请选中需要确认的回单!')
      }
    },
    // 取消确认回单
    async receiptCancel() {
      let length = this.$refs.searchTable.selectTableData.length
      if (length) {
        if (length === 1) {
          try {
            await this.$confirm('确定取消此回单?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            //todos 删除地址
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$message.error('只能选中一个!')
        }
      } else {
        this.$message.error('请选中需要取消的回单!')
      }
    },
    // 回单异常
    async receiptError() {
      let length = this.$refs.searchTable.selectTableData.length
      if (length) {
        if (length === 1) {
          try {
            await this.$confirm('确定此回单异常?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            //todos 删除地址
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$message.error('只能选中一个!')
        }
      } else {
        this.$message.error('请选中需要操作的回单!')
      }
    },

    // 查看详情
    getReceiptDetail(row, column) {
      this.$refs.detail.visible = true
      this.$refs.detail.form = { ...row }
      console.log(row, column)
    },
    
    //获取表格数据
    async getTableData(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      if (params.deliveryDt && params.deliveryDt.length) {
        params.sendDateStart = params.deliveryDt[0]
        params.sendDateEnd = params.deliveryDt[1]
        // params.sendDt = null
      } else {
        this.$message.error('请选择统计区间')
        return
      }
      this.loading = true
      
      try {
        const res = await receiptManagement({ ...params })
        this.tableData = res.data.items
        this.total = res.data.total
        this.tableSummary = res.data.summary
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      console.log(fields)
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      console.log(fields, colSize)
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },
    
    //获取表格头配置
    async getTableField() {
      
    },
    //获取搜索字段配置
    async getSearchField() {
      
    },
    // 导出功能
    exportReceiptList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params = isParamsNotNull(params)
      if (params.deliveryDt && params.deliveryDt.length) {
        params.sendDateStart = params.deliveryDt[0]
        params.sendDateEnd = params.deliveryDt[1]
      } else {
        this.$message.error('请选择统计区间')
        return
      }
      this.loading = true
      try {
        this.tempFileName = new Date().getTime()
        params.tempFileName = this.tempFileName
        params.header = []
        params.props = []
        this.tableColumns.forEach(p => {
          if (p.title !== '回单图片') {
            params.header.push(p.title)
            params.props.push(p.field)
          }
        })
        exportReceiptList({ ...params })
        this.loading = false
        this.$message.success('正在导出，请稍后...')
        this.dataRefresh()
      } catch (error) {
        this.loading = false
      }
    },
    dataRefresh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        console.log('调用刷新数据方法')
        this.doQueryExcel()
        // 由于数据量比较大，30秒刷新一次数据
      }, 1000 * 3)
    },
    // 轮询查询文件是否导出成功
    doQueryExcel() {
      const params = {
        tempFileName: this.tempFileName,
      }
      queryExcelDownloadStatus(params).then((response) => {
        if (response.code === '000') {
          // 如果下载状态为true则表示可以下载了，直接调用下载方法，否则就不执行任何操作，继续轮询
          if (response.data.downloadStatus) {
            this.doDownloadExcel()
          }
        }
      })
    },
    // 下载导出的表格
    async doDownloadExcel() {
      // 如果已经开始调用下载表格方法了说明数据已经完成写入表格功能，需要将定时器停止了
      this.clear()
      const loading = this.$loading({
        lock: true,
        text: '正在导出...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)',
        target: document.getElementById('app'),
      })
      const params = {
        fileName: this.tempFileName,
      }
      try {
      let response = await downloadExcel(params)
        if (!response) {
          return
        }
        loading.close()
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '回单列表.csv')
        document.body.appendChild(link)
        link.click()
        this.tempFileName = null
      } catch(error) {
        this.tempFileName = null
        loading.close()
      }
    },
    clear() {
      // 清除计时器
      clearInterval(this.intervalId)
      // 停止计时
      this.intervalId = null
    },
  }
}
</script>

<style scoped>
.scan-label {
  font-size: 14px;
  color: #666;
  line-height: 28px;
}
.tip {
  margin-left: 5px;
  line-height: 28px;
}

</style>