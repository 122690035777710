import request from '@/utils/http.js'

/**
 * 查询会话列表数据
 * @param {object} data
 * @returns
 */
export const queryConversationList = data => {
    return request({
        url: '/biz/way/oms/queryConversationList',
        method: 'post',
        data
    })
}
/**
 * 查询会话列表数据
 * @param {object} data
 * @returns
 */
export const submitConversationContent = data => {
    return request({
        url: '/biz/way/oms/submitConversationContent',
        method: 'post',
        data
    })
}
/**
 * 查询会话列表数据
 * @param {object} data
 * @returns
 */
export const sendConversationContent = data => {
    return request({
        url: '/biz/way/oms/sendConversationContent',
        method: 'post',
        data
    })
}

/**
 * 查询会话列表数据
 * @param {object} data
 * @returns
 */
export const queryConversationContentList = data => {
    return request({
        url: '/biz/way/oms/queryConversationContentList',
        method: 'post',
        data
    })
}
