
const searchFields = vm => {
    // 搜索框
    return []
}

const baseColumn = vm => {
    // 基础表头
    return []
}

const operateList = vm => {
    // 按钮
    return [
        { name: '创建会话', btnFn: vm.createConversation, type: 'primary', plain: true, icon: 'el-icon-wechat'},
    ]
}

const fixedBtnList = vm => {
    // 固定列
}

const selectTableSummary = []

export default {
    searchFields,
    baseColumn,
    operateList,
    fixedBtnList,
    selectTableSummary
}
