<template>
  <div>
    <search-table
        ref="searchTable"
        labelWidth="70px"
        :loading="loading"
        :searchFields="searchFields"
        :colSize="colSize"
        :total="total"
        :tableData="tableData"
        :tableColumns="tableColumns"
        :baseColumns="baseColumns"
        :checkboxColumn="false"
        :hasPagination="true"
        :showFooter="false"
        :showSetting="false"
        :showColumnSearch="false"
        :showSortable="false"
        :searchFieldSetting="false"
        :autoBtnList="autoBtnList"
        :proxyParams="proxyParams"
        :initFormData="initFormData"
        :fieldOperateList="fieldOperateList"
        @saveTableField="saveTableField"
        @saveSearchField="saveSearchField"
        @saveSortField="saveSortField"
        @getTableData="getTableData"/>
    <el-drawer title="创建会话" ref="createConversation" v-if="createVisible" :visible.sync="createVisible" size="50%">
      <span>
        <create-conversation/>
      </span>
    </el-drawer>
    <el-drawer title="咨询投诉记录" ref="StartConversation" v-if="startVisible" :visible.sync="startVisible" size="50%">
      <span>
        <start-conversation :conversation-id="conversationId" :type="type"  :theme="theme" :create-dt="createDt"/>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import SearchTable from '@/components/LcSearchTable'
import StartConversation from '@/views/consult/consult-complaints/components/StartConversation'
import CreateConversation from '@/views/consult/consult-complaints/components/CreateConversation'
import config from '@/const/consult/consult-complaints.js'
import {latestWeek, isParamsNotNull} from '@/utils/util'
import {columnSetting} from '@/api/common'
import column from '@/mixins/column.js'
import {queryConversationList} from '@/api/consult.js'

export default {
  name: 'ConsultComplaints',
  mixins: [column],
  components: {StartConversation, CreateConversation, SearchTable},
  data() {
    return {
      loading: false,
      total: 0,
      initFormData: {
        createDt: latestWeek,
      },
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      baseColumns: [],
      tableData: [],
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 6, //用户设置展示列比例
      createVisible: false,
      startVisible: false,
      conversationId: null,
      type: null,
      theme: null,
      createDt: null,
    }
  },
  computed: {
    autoBtnList() {
      return config.operateList(this)
    },
    fieldOperateList() {
      return {
        entryConversation: this.startConversation,
      }
    }
  },
  methods: {
    async getTableData(params) {
      if (!params.createDt || !params.createDt.length) {
        this.$message.error('请选择时间')
        return
      }
      params = isParamsNotNull(params)
      this.loading = true
      try {
        const res = await queryConversationList({...params})
        this.tableData = res.data.items
        this.total = res.data.total
        this.baseColumns = res.data?.tableSettingFields.filter(p => p.field !== 'id') || config.baseColumn(this).filter(p => p.field !== 'id')
        this.path = res.data?.path
        this.colSize = res.data?.colSize
        this.initConfig()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
      console.log(this.baseColumns)
    },
    // 创建会话
    createConversation() {
      this.createVisible = true
    },
    // 进入会话
    startConversation(row) {
      this.conversationId = row.id
      this.type = row.type
      this.theme = row.theme
      this.createDt = row.createDt
      this.startVisible = true
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          tableFields: fields
        })
        await this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {

    },
    // 处理数据
    proxyParams(params) {

    },
    async saveSortField(fields) {
      console.log(fields)
      this.sortFields = fields
    },
    //获取表格头配置
    async getTableField() {

    },
    //获取搜索字段配置
    async getSearchField() {

    },
  }
}
</script>

<style scoped>

</style>
