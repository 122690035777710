import request from '@/utils/http.js'

/**
 * 回单分页查询
 * @param {object} data 
 * @returns 
 */
export const receiptManagement = data => {
  return request({
    url: '/biz/receipt/oms/receiptManagement',
    method: 'post',
    data
  })
}

/**
 * 回单确认
 * @param {object} data 
 * @returns 
 */
export const receiptConfirm = data => {
  return request({
    url: '/biz/receipt/oms/receiptConfirmation',
    method: 'post',
    data
  })
}

/**
 * 回单导出
 * @param {object} data
 * @returns
 */
export const exportReceiptList = data => {
  return request({
    url: '/biz/receipt/oms/exportReceiptList',
    method: 'post',
    data
  })
}

/**
 * 查询导出状态
 * @param params
 * @returns {*}
 */
export function queryExcelDownloadStatus(params) {
  return request({
    url: '/biz/plan/queryPlanbillExcelDownloadStatus',
    method: 'get',
    params
  })
}

/**
 * 下载表格
 * @param params
 * @returns {*}
 */
export function downloadExcel(params) {
  return request({
    url: '/biz/plan/downloadExcelPlanbill',
    method: 'post',
    responseType: 'blob',
    params
  })
}