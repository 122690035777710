<template>
  <div>
    <div>
      <el-form ref="form" :model="ruleForm" :disabled="conversations.length > 0" label-width="84px" size="mini" label-position="left" style="padding-left: 50px; padding-right: 50px">
        <el-form-item label="选择类型：">
          <el-radio v-model="ruleForm.type" label="0" @change="switchOptions">咨询</el-radio>
          <el-radio v-model="ruleForm.type" label="1" @change="switchOptions">投诉</el-radio>
        </el-form-item>
        <el-form-item label="选择主题：">
          <el-select v-model="ruleForm.theme">
            <el-option v-for="item in options" :key="item.label" :value="item.label" :label="item.label"/>
          </el-select>
        </el-form-item>
        <el-divider/>
      </el-form>
    </div>
    <div style="height: calc(100vh - 269px); overflow: auto">
      <span style="display: block; text-align: center" @click="moreContent">沟通记录</span>
      <ul class="ul">
        <li class="li" v-for="(item, index) in conversations" :key="index" >
          <el-avatar :src="avatar.length > 0 ? avatar : defaultAvatar"
                     :class="roleType === item.createUserRole ? 'avatar_right':'avatar_left'"/>
          <div :class="['tip', roleType === item.createUserRole ? 'tip_right': 'tip_left']"/>
          <div :class="['content_show', roleType === item.createUserRole ? 'right_show' : 'left_show']">
            {{item.replyContent}}
          </div>
          <div :class="['time_show',  roleType === item.createUserRole ? 'time_show_right' : 'time_show_left']">
            {{item.createDt}}
          </div>
        </li>
      </ul>
    </div>
    <div style="margin-top: 15px; bottom: 25px; width: 93%; padding-left: 50px">
      <el-input placeholder="请输入内容" v-model="latestReplyContent" @keyup.enter.native="sendContent">
        <el-button
            slot="append" type="primary"
            style="background-color: #346eb4; color: #FFFFFF; border-radius:0 4px 4px 0; padding-top: 14px"
            @click="sendContent">
          发送消息
        </el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
import { submitConversationContent, sendConversationContent, queryConversationContentList } from '@/api/consult.js'
export default {
  name: 'CreateConversation',
  data() {
    return {
      ruleForm: {
        type: '0',
        theme: null,
      },
      conversationId: 0,
      defaultAvatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      avatar: '',
      options: [],
      latestReplyContent: null,// 回复的消息内容
      conversations: [],
      consultOptions: [
        {label: '系统咨询'},
        {label: '合作咨询'},
        {label: '其它'},
      ],
      complainOptions: [
        {label: '服务质量'},
        {label: '运作质量'},
        {label: '信息不畅'},
        {label: '其它'},
      ],
      roleType: '2', // 角色类别为2代表是发货方，oms端只有发货方有权限登录
      pageNum: 1,
      pageSize: 20,
      total: 0,
      modifyDate: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.options = this.consultOptions
      this.ruleForm.theme = this.options[0].label
    },
    switchOptions() {
      this.options = this.ruleForm.type === '1' ? this.complainOptions : this.consultOptions
      this.ruleForm.theme = this.options[0].label
    },
    sendContent() {
      if (this.latestReplyContent.trim().length > 0) {
        // 如果会话的内容长度大于0，说明是会话已被创建
        if (this.conversations.length <= 0) {
          // 提交发送信息内容
          submitConversationContent({
            ...this.ruleForm,
            'latestReplyContent': this.latestReplyContent
          }).then(response => {
            if (response.code === '000') {
              this.conversationId = response.data.conversationId
              this.modifyDate = this.modifyDate === null ? response.data.createDt : this.modifyDate
              // 发送成功则将数据添加到conversations中
              this.conversations.push(response.data)
              this.latestReplyContent = ''
            }
          })
        } else {
          sendConversationContent({
            'conversationId': this.conversationId,
            'latestReplyContent': this.latestReplyContent
          }).then(response => {
            if (response.code === '000') {
              this.modifyDate = this.modifyDate === null ? response.data.createDt : this.modifyDate
              this.conversations.push(response.data)
              this.latestReplyContent = ''
            }
          })
          // 提交发送的信息
        }
      } else {
        this.$message.warning('发送信息不能为空')
      }
    },
    // 获取更多内容
    moreContent() {
      this.$message.warning('没有更多了')
      return
      if (this.conversationId > 0) {
        if (this.pageNum === 1 || this.pageNum * this.pageSize < this.total) {
          queryConversationContentList({
            'conversationId': this.conversationId,
            'createDt':this.modifyDate,
            'pageNum':this.pageNum,
            'pageSize':this.pageSize
          }).then(response => {
            if (response.code === '000' && response.data.total > 0) {
              this.pageNum++
              this.total = response.data.total
              this.conversations.unshift(...response.data.items)
            } else {
              this.$message.warning('没有更多了')
            }
          })
        } else {
          this.$message.warning('没有更多了')
        }
      } else {
        this.$message.error('请先创建会话消息')
      }
    }
  }
}
</script>

<style scoped>
.avatar_left {
  margin-left: -23px;
  float: left;
}

.avatar_right {
  margin-right: -4px;
  float: right;
}

.tip {
  width: 0;
  height: 0;
  /* 对话框尖角设置 */
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 2px solid transparent;
  border-right: 1px solid transparent;
  margin-top: 10px;
}

.tip_left {
  border-right: 12px solid #efeae7;
  float: left;
}

.tip_right {
  border-left: 12px solid #5194f4;
  float: right;
}

.content_show {
  max-width: 450px;
  border-radius: 4px;
  padding: 10px;
  word-break: break-all;
  min-height: 38px;
}

.left_show {
  float: left;
  background: #efeae7;
  color: black;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.3);
}

.right_show {
  background: #5194f4;
  color: white;
  float: right;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
}

.time_show {
  clear: both;
  padding-top: 15px;
}

.time_show_left {
  padding-left: 30px;
  float: left;
}

.time_show_right {
  padding-right: 48px;
  float: right;
}

.ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  padding-bottom: 20px;
}

.li {
  font-size: 14px;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px 5px 0 23px;
}
</style>
